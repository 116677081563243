<!-- 底部导航 -->
<template>
    <div :class="['bottom', { 'bottomshow': !show }]">
        <div class="list">
            <div>
                <p>
                    <span @click="skip('/home')">关于我们</span>
                    <i @click="uslist(value.class, '/home')" v-for="value in list[0]">{{ value.name }}</i>
                </p>
                <p>
                    <span @click="skip('/product')">产品中心</span>
                    <i  @click="skip('/product', value.id)" v-for="value in CateList">{{ value.title }}</i>
                </p>
                <p>
                    <span @click="skip('/engineering')">工程案例</span>
                    <i @click="skip('/engineering')" v-for="value in list[2]">{{ value.name }}</i>
                </p>
                <p>
                    <span @click="skip('/journalism')">新闻中心</span>
                    <i @click="skip('/journalism')" v-for="value in list[3]">{{ value.name }}</i>
                </p>
                <p>
                    <span @click="skip('/contact')">联系我们</span>
                    <i @click="uslist(value.class, '/contact')" v-for="value in list[4]">{{ value.name }}</i>
                </p>
            </div>
            <p><img :src="configuration.wx">官方微信</p>
        </div>
        <div class="detailedness">
            <div>
                <p>
                    <span @click="copy(configuration.tel)"><i class="iconfont icon-dianhua-yuankuang"></i>服务热线：{{ configuration.tel }}</span>
                    <span @click="copy(configuration.email)"><i class="iconfont icon-youxiang"></i>邮箱：{{ configuration.email }}</span>
                    <span @click="copy(configuration.address)"><i class="iconfont icon-dizhi"></i>地址：{{ configuration.address }}</span>
                </p>
                <p> Copyright © {{ configuration.company_name }} 版权所有 <a target="_blank"
                        href="https://beian.miit.gov.cn/">备案号：{{ configuration.company_no }}</a></p>
            </div>
            <p><img :src="configuration.wx" alt="">官方微信</p>
        </div>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import bus from '@/config/emit'

export default {
    name: "bottom",
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {
            CateList:[]
        };
    },
    props: ['list', 'show', 'configuration','path'],
    //计算属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    //方法集合
    methods: {
        getCateList(){
            this.$httpRequest('/index/getCateList').then(res => {
                this.CateList=res.data
            })
        },
        skip(route, id) {
            if (route == '/product') {
                bus.$emit('switchover', id)
            }
            this.showAside = false
            this.$router.push({
                name: route,
                params: { id, }
            })
        },
        uslist(dom, path) {
            if (this.path !== path) {
                this.$router.push({
                    path: path
                })
                localStorage.setItem('path', dom)
                return
            }
            let top = document.getElementsByClassName(dom)[0].offsetTop
            this.$customApi.scrollToTop(top - 100)
        },
        copy(text) {
            var that = this
            var input = document.createElement("input"); // 直接构建input
            input.value = text
            document.body.appendChild(input); // 添加临时实例
            input.select(); // 选择实例内容
            document.execCommand("Copy"); // 执行复制
            document.body.removeChild(input); // 删除临时实例
            this.$message({
                message: '复制成功',
                type: 'success'
            });
        },
    },
    beforeCreate() { }, //生命周期 - 创建之前
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.getCateList()
    },
    beforeMount() { }, //生命周期 - 挂载之前
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，激活
    deactivated() { }, //如果页面有keep-alive缓存功能，非激活
}
</script>
<style lang="less">
@media screen and (min-width: 1600px) {
    .bottom {

        &>.detailedness {
            padding: 0px .625rem .375rem !important;

            &>div {

                &>p {
                    font-size: .15rem !important;
                    margin: .25rem 0 !important;

                    &>span:nth-child(1) {
                        &>i {
                            margin: 0 .125rem 0 0 !important;
                        }
                    }

                    &>span {
                        font-size: .15rem !important;

                        &>i {
                            font-size: .225rem !important;
                            margin: 0 .125rem !important;
                        }
                    }
                }
            }

            &>p {
                font-size: .15rem !important;

                &>img {
                    width: 1.5rem !important;
                    margin-bottom: .375rem !important;
                }
            }
        }

        &>.list {
            padding: 1rem .625rem .75rem !important;

            &>div {

                &>p {

                    &>i {
                        font-size: .15rem !important;
                        margin-bottom: .25rem !important;
                    }

                    &>span {
                        font-size: .2rem !important;
                        margin-bottom: .375rem !important;
                    }
                }
            }

            &>p {
                font-size: .15rem !important;

                &>img {
                    width: 1.5rem !important;
                    margin-bottom: .375rem !important;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .bottom {
        padding-top: 20px;

        &>.list {
            display: none !important;
        }

        &>.detailedness {
            padding: 0px 20px 30px !important;

            &>p {
                display: flex !important;

                &>img {
                    width: 90px !important;
                    margin-left: 20px;
                }
            }

            &>div {
                flex-direction: column;

                &>p {
                    flex-direction: column;
                    margin: 10px 0 !important;

                    &>a {
                        margin:10px 0 0 0 !important;
                    }

                    &>span {
                        &>i {
                            margin: 0 10px 0 0 !important;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .bottomshow {
        display: none !important;
    }
}

.bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #2359a6;

    &>.detailedness {
        display: flex;
        justify-content: space-between;
        padding: 0px 50px 10px;
        box-sizing: border-box;

        &>div {
            width: 100%;

            &>p {
                display: flex;
                justify-content: center;
                color: #fff;
                font-size: 12px;
                margin: 30px 0;
                cursor: pointer;

                &>a:hover {
                    color: tomato;
                }

                &>a {
                    color: #fff;
                    margin-left: 10px;
                }

                &>span:hover {
                    color: #fff;
                }

                &>span:nth-child(1) {
                    &>i {
                        margin: 0 10px 0 0;
                    }
                }

                &>span {
                    color: #ffffff90;
                    font-size: 12px;
                    cursor: pointer;

                    &>i {
                        font-size: 18px;
                        margin: 0 10px;
                    }
                }
            }
        }

        &>p {
            flex-direction: column;
            align-items: flex-end;
            color: #fff;
            display: none;
            font-size: 12px;

            &>img {
                width: 120px;
                margin-bottom: 30px;
            }
        }
    }

    &>.list {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 80px 50px 60px;
        box-sizing: border-box;

        &>div {
            display: flex;
            flex: 1;

            &>p {
                flex: 1;
                display: flex;
                flex-direction: column;

                &>i:hover {
                    color: #ffffff;
                }

                &>i {
                    font-size: 12px;
                    margin-bottom: 20px;
                    color: #ffffff90;
                    cursor: pointer;
                }

                &>span {
                    font-size: 16px;
                    margin-bottom: 30px;
                    color: #fff;
                    cursor: pointer;
                }
            }
        }

        &>p:hover {
            &>img {
                transform: scale(1.4);
            }
        }

        &>p {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            color: #fff;
            font-size: 12px;

            &>img {
                width: 120px;
                margin-bottom: 30px;
                transition: all .3s;
            }
        }
    }
}
</style>