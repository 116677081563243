<!-- 首页 -->
<template>
    <div ref="home" class='home'>
        <div class="slideshow">
            <van-swipe ref="swipe" :autoplay="3000" @change="change">
                <van-swipe-item>
                    <div class="fiche" :style="'height:' + height">
                        <img src="../static/home/h1.png">
                        <div :class="{ 'div': 0 == initial }">
                            <p>源于自然，高于自然</p> 
                            <p>我们的充土技术，为您创造一个安全一舒适的生活环境</p>
                            <span>ORIGINATING FROM NATURE HIGHER THAN NATURE</span>
                        </div>
                    </div>
                </van-swipe-item>
                <van-swipe-item>
                    <div class="fiche" :style="'height:' + height">
                        <img src="../static/home/h2.png">
                        <div :class="{ 'div': 1 == initial }">
                            <p>传承古法，创新未来</p> 
                            <p>我们的夯土建筑，承载着历史与现代的完美结合</p>
                            <span>NHERITING ANCIENT METHODS AND INNOVATING THE FUTURE</span>
                        </div>
                    </div>
                </van-swipe-item>
                <van-swipe-item>
                    <div class="fiche" :style="'height:' + height">
                        <img src="../static/home/h3.png">
                        <div :class="{ 'div': 2 == initial }">
                            <p>夯实基础，塑造未来</p> 
                            <p>我们的穷土工艺，为您打造坚固耐用的建筑</p>
                            <span>CONSOLIDATE THE FOUNDATION AND SHAPETHEFUTURE</span>
                        </div>
                    </div>
                </van-swipe-item>
                <template #indicator>
                    <div class="custom-indicator">
                        <p :class="{ 'p': index == initial }" v-for="(item, index) in 3" :key="item">
                            <span></span>
                        </p>
                    </div>
                </template>
            </van-swipe>
            <div v-if="swipe" @click="swipe.prev" class="controller1">
                <van-icon name="arrow-left" />
            </div>
            <div v-if="swipe" @click="swipe.next" class="controller2">
                <van-icon name="arrow" />
            </div>
        </div>
        <div :class="['ServiceItem', 'Domlist', { 'Cartoon': Dom[0].flag }]">
            <p> service </p>
            <h3>服务项目</h3>
            <span></span>
            <div>
                <article>
                    <img src="../static/home/1.png">
                    <h3>来样定制 全国施工发货</h3>
                    <p>公司拥有多支研发团队，支持来样定制。技术过硬的施工团队全国施工</p>
                </article>
                <article>
                    <img src="../static/home/2.png">
                    <h3>新型材料销售施工</h3>
                    <p>工厂直营新型夯土墙技术配方 夯砼 UHPC超强夯土板 夯土抹装材料 老土墙 夯土砖</p>
                </article>
                <article>
                    <img src="../static/home/3.png">
                    <h3>夯土工程技术指导</h3>
                    <p>夯土项目全程技术指导 施工人员专业化技术培训 项目施工管理</p>
                </article>
                <article>
                    <img src="../static/home/4.png">
                    <h3>支持夯土材料代加工</h3>
                    <p>与全国多个工厂合作，支持夯土新型材料贴牌代加工</p>
                </article>
            </div>
        </div>
        <div :class="['product', 'Domlist', { 'Cartoon': Dom[1].flag }]">
            <header>
                <nav>
                    <p> Product Center </p>
                    <h3>产品中心</h3>
                    <span></span>
                </nav>
                <div>
                    <div v-for="value in CateList" :key="value.id" @mouseenter="alteration('product', value.id)" @click="alteration('product', value.id)"
                        :class="{ 'div': product == value.id }">
                        <p>
                            <img :src="$imgurl+value.img" alt="">
                            <img :src="$imgurl+value.img_selected" alt="">
                        </p>
                        <span>{{ value.title }}</span>
                        <i>{{value.title_en}}</i>
                    </div>
                </div>
            </header>
            <main>
                <div v-for="(value,index) in GoodsList" :key="value.id" @click="skip('/details',value.id)">
                    <img :src="$imgurl+value.goods_image" alt="">
                    <div>
                        <p>{{ value.goods_name }}</p>
                        <span>{{ value.goods_intro }}</span>
                    </div>
                </div>
            </main>
            <button @click="skip('/product')" type="primary">more+</button>
        </div>
        <div :class="['introduce', 'Domlist', { 'Cartoon': Dom[2].flag }]">
            <p> fuyue profile </p>
            <h3>公司简介</h3>
            <span></span>
            <header>
                <div>
                    <p>
                        <count-to v-if="Dom[2].dom" :useEasing="false" :endVal="200" :duration="2"></count-to>
                        <b>+<i>家</i></b>
                    </p>
                    世界500强企业合作
                </div>
                <div>
                    <p>
                        <count-to v-if="Dom[2].dom" :useEasing="false" :endVal="10000" :duration="2"></count-to>
                        <b>+<i>项</i></b>
                    </p>
                    精品案例
                </div>
                <div>
                    <p>
                        <span>NO.<count-to v-if="Dom[2].dom" :useEasing="false" :endVal="10"
                                :duration="2"></count-to></span>
                    </p>
                    精品案例
                </div>
                <div>
                    <p>
                        <count-to v-if="Dom[2].dom" :useEasing="false" :endVal="400" :duration="2"></count-to>
                        <b>+<i>项</i></b>
                    </p>
                    专业设计团队
                </div>
            </header>
            <main>
                <div>
                    <h2 style="text-align: left;width: 100%;margin-bottom: 20px;">河南泥筑新材料有限公司</h2>
                    <span>集研发生产，施工及销售为一体的新型材料有限公司。
                        公司多年以来专注夯土材料，拥有多支专业经验丰富，技术一流的设计研发团队为您打造高品质的建筑艺术品。公司的宗旨是诚信为本，制作精美，服务上乘。为您创造物美价廉，质量过硬的新型材料。</span>
                </div>
                <p class="img">
                    <img src="../static/home/5.png">
                </p>
            </main>
        </div>
        <div :class="['case', 'Domlist', { 'Cartoon': Dom[3].flag }]">
            <header>
                <p> Engineering case </p>
                <h3>工程案例</h3>
                <span></span>
            </header>
            <main>
                <van-swipe ref="verticalSwipe" :autoplay="5000" :style="'height:' + verticalH" vertical @change="vertical">
                    <van-swipe-item v-for="(item, index) in caseList" :key="item.id">
                        <div class="img" @click="skip('/case',item.id)">
                            <p :style="'background: url(' + $imgurl+item.img + ') no-repeat;'"></p>
                            <img :style="'height:' + verticalH" :src="$imgurl+item.img">
                            <span :class="{ 'span': initial1 == index }">{{ item.title }}</span>
                        </div>
                    </van-swipe-item>
                    <template #indicator>
                        <div class="custom-indicator">
                            <p @click="verticalSwipe.swipeTo(index)" :class="{ 'p': index == initial1 }"
                                v-for="(item, index) in caseList.length" :key="item">
                                <span></span>
                            </p>
                        </div>
                    </template>
                </van-swipe>
            </main>
            <button @click="skip('/engineering')" type="primary">more+</button>
        </div>
        <!-- <div :class="['Cooperation', 'Domlist', { 'Cartoon': Dom[4].flag }]">
            <header>
                <p>Cooperation partner</p>
                <h3>合作伙伴</h3>
                <span></span>
            </header>
            <main>
                <div v-for="value in 10">
                    <p></p>
                    <p></p>
                    <p></p>
                    <p></p>
                    <img src="../static/home/4.jpg" alt="">
                </div>
            </main>
        </div> -->
        <div :class="['News', 'Domlist', { 'Cartoon': Dom[4].flag }]">
            <header>
                <p> News Center </p>
                <h3>新闻中心</h3>
                <span></span>
            </header>
            <!-- <nav>
                <p @mouseenter="alteration('Newsindex', 0)" :class="{ 'p': Newsindex == 0 }">
                    <i>公司新闻</i>
                    <span></span>
                </p>
                <p @mouseenter="alteration('Newsindex', 1)" :class="{ 'p': Newsindex == 1 }">
                    <i>行业资讯</i>
                    <span></span>
                </p>
                <p @mouseenter="alteration('Newsindex', 2)" :class="{ 'p': Newsindex == 2 }">
                    <i>常见问答</i>
                    <span></span>
                </p>
            </nav> -->
            <main>
                <van-swipe ref="NewsSwipe" :show-indicators="false" :loop="false" @change="Newschange" :width="width">
                    <van-swipe-item v-for="value in articleList" :key="value.id">
                        <div class="fiche" @click="skip('/case',value.id)">
                            <div class="img">
                                <p :style="'background: url(' + $imgurl+value.img + ') no-repeat;'"></p>
                                <img style="height: 100%;" :src="$imgurl+value.img">
                            </div>
                            <div class="text">
                                <span>{{ value.create_time }}</span>
                                <h1>{{ value.title }}</h1>
                                <p> {{ value.intro }}</p>
                            </div>
                        </div>
                    </van-swipe-item>
                </van-swipe>
                <div v-if="NewsSwipe" @click="NewsSwipe.prev" class="controller1">
                    <i class="el-icon-back"></i>
                </div>
                <div v-if="NewsSwipe" @click="NewsSwipe.next" class="controller2">
                    <i class="el-icon-right"></i>
                </div>
            </main>
            <button @click="skip('/journalism')" type="primary">more+</button>
        </div>
    </div>
</template>

<script>
// :duration="2"：数字过渡的时间为2秒 :separator="' '"：数字使用空格隔开 :decimals="0"：数字显示0位小数 :startVal="0"：数字从0开始递增 :useEasing="false"：数字递增不使用缓动
import CountTo from 'vue-countup-v2'

export default {
    //import引入的组件需要注入到对象中才能使用
    components: {
        CountTo
    },
    data() {
        //这里存放数据
        return {
            height: '11.5rem',
            verticalH: '8rem',
            width: 300,
            arrow: 'never',
            initial: 0,
            initial1: 0,
            initial2: 0,
            product: 0,
            Newsindex: 0,
            proportion: .3,
            swipe: '',
            verticalSwipe: '',
            NewsSwipe: '',
            CateList:[],
            GoodsList:[],
            caseList:[],
            articleList:[],
            Dom: [{}, {}, {}, {}, {}, {}],
        };
    },
    //计算属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {},
    methods: {
        skip(route, id) {
            this.$router.push({
                name: route,
                params: { id, }
            })
        },
        alteration(variable, index) {
            this[variable] = index
            if(variable=='product'){
                this.getGoodsList(this.product)
            }
        },
        change(index) {
            this.initial = index
        },
        vertical(index) {
            this.initial1 = index
        },
        Newschange(index) {
            this.initial2 = index
        },
        resize() {
            const screenWidth = window.innerWidth;
            if (screenWidth > 1000) {
                this.height = '11.5rem'
                this.arrow = 'never'
            } else {
                this.height = '23rem'
                this.arrow = 'always'
            }
            if (screenWidth > 1440) {
                this.verticalH = '8rem'
                this.proportion = .3
            } else if (screenWidth < 700) {
                this.verticalH = '200px'
                this.proportion = .6
            } else {
                this.verticalH = '10rem'
                if (screenWidth < 1200 && screenWidth > 700) {
                    this.proportion = .54
                } else {
                    this.proportion = .4
                }
            }
            this.Dompush()
            this.width = screenWidth * this.proportion
        },
        scroll() {
            const scrollPos = window.scrollY || window.scrollTop || document.getElementsByTagName('html')[0].scrollTop
            this.Dom.forEach((value, index) => {
                if (value.top < scrollPos + innerHeight - 100) {
                    this.Dom[index].flag = true
                    this.Dom[index].dom = true
                } else {
                    this.Dom[index].dom = false
                }
            })
        },
        Dompush() {
            this.Dom = []
            let list = [...document.getElementsByClassName('Domlist')]
            list.forEach(value => {
                this.Dom.push({
                    flag: false,
                    dom: false,
                    top: value.offsetTop
                })
            })
            this.scroll()
        },
        getCateList(){
            this.$httpRequest('/index/getCateList').then(res => {
                this.product=res.data[0].id
                this.CateList=res.data
                this.getGoodsList(this.product)
            })
        },
        getGoodsList(id) {
            this.$httpRequest('/index/getGoodsList', { cid:id }).then(res => {
                this.GoodsList = res.data
                this.GoodsList.splice(4)
            })
        },
        caseLists() {
            this.$httpRequest('/index/caseList',).then(res => {
                this.caseList = res.data.data
                this.caseList.splice(4)
            })
        },
        articleLists() {
            this.$httpRequest('/index/articleList').then(res => {
                this.articleList = res.data.list
                this.articleList.splice(4)
            })
        },
    },
    beforeCreate() { }, //生命周期 - 创建之前
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.getCateList()
        this.caseLists()
        this.articleLists()
    },
    beforeMount() { }, //生命周期 - 挂载之前
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        this.resize()
        window.addEventListener('scroll', () => {
            this.scroll()
        });
        window.addEventListener('resize', () => {
            this.resize()
        });
        this.swipe = this.$refs.swipe
        this.verticalSwipe = this.$refs.verticalSwipe
        this.NewsSwipe = this.$refs.NewsSwipe
        if (localStorage.getItem('path')) {
            let top = document.getElementsByClassName(localStorage.getItem('path'))[0].offsetTop
            this.$customApi.scrollToTop(top - 100)
            localStorage.removeItem('path')
        }
    },
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() {
        if (localStorage.getItem('path')) {
            let top = document.getElementsByClassName(localStorage.getItem('path'))[0].offsetTop
            this.$customApi.scrollToTop(top - 100)
            localStorage.removeItem('path')
        }
     }, //如果页面有keep-alive缓存功能，激活
    deactivated() { }, //如果页面有keep-alive缓存功能，非激活
}
</script>
<style lang="less">
@media screen and (max-width: 1600px) {

    .News {
        padding: 120px 0 !important;

        &>button {
            width: 112px !important;
            height: 38px !important;
            font-size: 16px !important;
            border-radius: 3px !important;
            margin: 30px auto 0 !important;
        }

        &>main {

            .controller1,
            .controller2 {
                width: 50px !important;
                height: 50px !important;
                font-size: 22px !important;
            }

            // .van-swipe-item:nth-child(1) {
            //     .fiche {
            //         padding: 0 50px 0 0 !important;
            //     }
            // }

            // .van-swipe-item:nth-last-child(1) {
            //     .fiche {
            //         padding: 0 0 0 50px !important;
            //     }
            // }

            .fiche {
                padding: 0 25px !important;

                &>.text {
                    min-height: 280px !important;
                    border-radius: 0 0 10px 10px !important;
                    padding: 50px 20px !important;

                    &>span {
                        font-size: 18px !important;
                    }

                    &>h1 {
                        font-size: 18px !important;
                        margin: 40px 0 !important;
                    }

                    &>p {
                        font-size: 14px !important;
                        margin-bottom: 40px !important;
                    }
                }

                &>.img {
                    height: 240px !important;
                    border-radius: 10px 10px 0 0 !important;

                    &>p {
                        filter: blur(3px) !important;
                    }
                }
            }
        }

        &>nav {
            width: 84% !important;
            padding: 20px 0 50px !important;

            &>p {
                margin-left: 40px !important;
                font-size: 18px !important;

                &>span {
                    height: 2px !important;
                    margin-top: 10px !important;
                }
            }
        }

        &>header {
            margin: 0 auto 50px !important;

            &>span {
                width: 24px !important;
                min-width: 24px !important;
                height: 2px !important;
                margin: 16px 0 0 !important;
            }

            &>h3 {
                font-size: 24px !important;
            }

            &>p {
                font-size: 45px !important;
            }
        }
    }

    .Cooperation {
        padding: 120px 0 !important;

        &>main {
            width: 80% !important;

            &>div {
                margin-bottom: 20px !important;

                &>img {
                    width: 100%;
                }
            }
        }

        &>header {
            margin: 0 auto 50px !important;

            &>span {
                width: 24px !important;
                min-width: 24px !important;
                height: 2px !important;
                margin: 16px 0 0 !important;
            }

            &>h3 {
                font-size: 24px !important;
            }

            &>p {
                font-size: 45px !important;
            }
        }
    }

    .case {
        padding: 120px 0 !important;

        &>button {
            width: 112px !important;
            height: 38px !important;
            font-size: 16px !important;
            border-radius: 3px !important;
            margin: 36px auto 0 !important;
        }

        &>main {
            margin: 20px auto 0 !important;

            .custom-indicator {
                border-radius: 8px !important;
                padding: 5px 0 !important;

                &>.p {
                    &>span {
                        height: 20px !important;
                    }
                }

                &>p {
                    width: 16px !important;
                    height: 22px !important;

                    &>span {
                        width: 12px !important;
                        height: 12px !important;
                        border-radius: 6px !important;
                    }
                }
            }

            .img {
                &>span {
                    font-size: 20px !important;
                }
            }
        }

        &>header {
            margin: 0 auto 50px !important;

            &>span {
                width: 24px !important;
                min-width: 24px !important;
                height: 2px !important;
                margin: 16px 0 0 !important;
            }

            &>h3 {
                font-size: 24px !important;
            }

            &>p {
                font-size: 45px !important;
            }
        }
    }

    .introduce {
        margin: 70px 0 120px !important;

        &>main {
            height: 400px !important;
            border-radius: 10px !important;
            box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1) !important;

            &>div {
                padding: 20px 50px !important;
                font-size: 14px !important;

                &>button {
                    width: 112px !important;
                    height: 38px !important;
                    font-size: 16px !important;
                    border-radius: 3px !important;
                    margin: 36px auto 0 !important;
                }
            }
        }

        &>span {
            width: 24px !important;
            height: 2px !important;
            margin: 16px auto 0 !important;
        }

        &>h3 {
            font-size: 24px !important;
        }

        &>p {
            font-size: 45px !important;
        }

        &>header {
            padding: 50px 0 120px !important;

            &>div {
                font-size: 16px !important;

                &>P {
                    font-size: 30px !important;

                    &>b {
                        margin-left: 10px !important;

                        &>i {
                            font-size: 16px !important;
                        }
                    }

                    &>span {
                        font-size: 60px !important;
                    }
                }
            }
        }
    }

    .ServiceItem {
        &>span {
            width: 24px !important;
            height: 2px !important;
            margin: 16px auto 0 !important;
        }

        &>h3 {
            font-size: 24px !important;
        }

        &>p {
            font-size: 45px !important;
        }
    }

    .slideshow {
        .fiche {
            &>div {
                font-size: 20px !important;
                &>span{
                    font-size: 16px !important;
                }
            }
        }
    }

    .product {

        &>button {
            width: 112px !important;
            height: 38px !important;
            font-size: 16px !important;
            margin: 36px auto 0 !important;
        }

        &>header {

            &>div {
                &>div {
                    &>p {
                        width: 56px !important;
                        height: 56px !important;
                    }

                    &>span {
                        font-size: 14px !important;
                    }

                    &>i {
                        font-size: 10px !important;
                    }
                }
            }

            &>nav {
                &>span {
                    width: 24px !important;
                    min-width: 24px !important;
                    height: 2px !important;
                    margin: 16px 0 0 !important;
                }

                &>h3 {
                    font-size: 24px !important;
                }

                &>p {
                    font-size: 45px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .home {

        &>.introduce {
            margin: 30px 0 60px !important;

            &>main {
                height: 300px !important;
                &>div {
                    font-size: 12px !important;
                }
            }

            &>h3 {
                font-size: 17px !important;
            }

            &>p {
                font-size: 30px !important;
            }

            &>header {
                flex-wrap: wrap;
                padding: 20px 0 70px !important;

                &>div {
                    width: 50% !important;
                    font-size: 12px !important;
                    margin-top: 20px;

                    &>P {
                        font-size: 20px !important;

                        &>b {
                            &>i {
                                font-size: 12px !important;
                            }
                        }

                        &>span {
                            font-size: 40px !important;
                        }
                    }
                }
            }
        }

        &>.product {
            padding: 50px 20px !important;

            &>main {
                flex-wrap: wrap !important;
                justify-content: space-between !important;

                &>div {
                    width: 45% !important;
                    height: 4rem !important;

                    &>div {

                        &>p {
                            font-size: 14px !important;

                        }

                        &>span {
                            font-size: 10px !important;
                        }
                    }
                }
            }

            &>header {
                flex-direction: column !important;
                align-items: flex-start !important;

                &>div {
                    width: 100% !important;
                    min-width: 100% !important;
                    justify-content: flex-start !important;
                    margin-top: 20px;
                    flex-wrap: wrap;

                    // &>div:nth-child(3),
                    // &>div:nth-child(1) {
                    //     border-bottom: 1px solid #e5e5e5;
                    // }

                    // &>div:nth-child(2) {
                    //     border: 1px solid #e5e5e5;
                    //     border-top: 0;
                    // }

                    // &>div:nth-child(5) {
                    //     border-left: 1px solid #e5e5e5;
                    //     border-right: 1px solid #e5e5e5;
                    // }

                    &>div {
                        width: calc(100% / 3) !important;
                        padding: 20px 10px !important;

                        &>span {
                            font-size: 12px !important;
                        }

                        &>i {
                            font-size: 10px !important;
                        }

                        &>p {
                            width: 48px !important;
                            height: 48px !important;
                        }
                    }
                }

                &>nav {
                    &>h3 {
                        font-size: 17px !important;
                    }

                    &>p {
                        font-size: 30px !important;
                    }
                }
            }
        }

        &>.ServiceItem {
            padding: 50px 0 !important;

            &>h3 {
                font-size: 17px !important;
            }

            &>p {
                font-size: 30px !important;
            }

            &>div {
                flex-wrap: wrap;

                &>article:nth-child(1) {
                    border-bottom: 1px solid #e5e5e5;
                    border-right: 1px solid #e5e5e5;
                    margin-top: 30px !important;
                }

                &>article:nth-child(2) {
                    border-bottom: 1px solid #e5e5e5;
                    margin-top: 30px !important;
                }

                &>article:nth-child(3) {
                    border-right: 1px solid #e5e5e5;
                }

                &>article {
                    width: 50% !important;
                    margin-top: 0px !important;
                    padding: 20px 20px !important;

                    &>h3 {
                        font-size: 16px !important;
                    }

                    &>p {
                        font-size: 12px !important;
                    }

                    &>img {
                        width: 80px !important;
                    }
                }
            }
        }

        &>.slideshow {
            height: 23rem !important;

            .controller1,
            .controller2 {
                width: 35px !important;
                height: 35px !important;
                font-size: 22px !important;
            }

            .el-carousel__indicators {
                display: none !important;
            }

            .fiche {
                align-items: normal !important;

                &>img {
                    min-height: 100% !important;
                    width: auto !important;
                }
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .home {

        .News {
            padding: 40px 0 !important;

            &>nav {
                padding: 15px 0 40px !important;

                &>p {
                    margin-left: 30px !important;
                    font-size: 14px !important;
                }
            }

            &>main {

                .controller1,
                .controller2 {
                    width: 30px !important;
                    height: 30px !important;
                    font-size: 16px !important;
                }

                // .van-swipe-item:nth-child(1) {
                //     .fiche {
                //         padding: 0 36px 0 0 !important;
                //     }
                // }

                // .van-swipe-item:nth-last-child(1) {
                //     .fiche {
                //         padding: 0 0 0 36px !important;
                //     }
                // }

                .fiche {
                    padding: 0 18px !important;

                    &>.text {
                        min-height: 170px !important;
                        border-radius: 0 0 10px 10px !important;
                        padding: 30px 15px !important;

                        &>span {
                            font-size: 16px !important;
                        }

                        &>h1 {
                            font-size: 16px !important;
                            margin: 20px 0 !important;
                        }

                        &>p {
                            font-size: 12px !important;
                            margin-bottom: 20px !important;
                        }
                    }

                    &>.img {
                        height: 160px !important;
                        border-radius: 10px 10px 0 0 !important;

                        &>p {
                            filter: blur(3px) !important;
                        }
                    }
                }
            }

            &>header {
                margin: 0 auto 30px !important;

                &>span {
                    width: 24px !important;
                    min-width: 24px !important;
                    height: 2px !important;
                    margin: 16px 0 0 !important;
                }

                &>h3 {
                    font-size: 20px !important;
                }

                &>p {
                    font-size: 30px !important;
                }
            }
        }

        .Cooperation {
            padding: 70px 0 !important;

            &>main {
                width: 90% !important;

                &>div {
                    width: calc(100% / 3.2) !important;
                }
            }

            &>header {
                margin: 0 auto 50px !important;

                &>span {
                    width: 24px !important;
                    min-width: 24px !important;
                    height: 2px !important;
                    margin: 16px 0 0 !important;
                }

                &>h3 {
                    font-size: 20px !important;
                }

                &>p {
                    font-size: 30px !important;
                }
            }
        }

        .case {
            padding: 70px 0 !important;

            &>main {
                margin: 10px auto 0 !important;

                .custom-indicator {
                    border-radius: 6px !important;
                    padding: 5px 0 !important;

                    &>.p {
                        &>span {
                            height: 15px !important;
                        }
                    }

                    &>p {
                        width: 12px !important;
                        height: 17px !important;

                        &>span {
                            width: 10px !important;
                            height: 10px !important;
                            border-radius: 5px !important;
                        }
                    }
                }

                .img {
                    &>p {
                        filter: blur(5px);
                    }

                    &>span {
                        font-size: 15px !important;
                    }
                }
            }

            &>header {
                margin: 0 auto 50px !important;

                &>span {
                    width: 24px !important;
                    min-width: 24px !important;
                    height: 2px !important;
                    margin: 16px 0 0 !important;
                }

                &>h3 {
                    font-size: 20px !important;
                }

                &>p {
                    font-size: 30px !important;
                }
            }
        }

        &>.introduce {
            margin: 30px 0 60px !important;

            &>main {
                height: 300px !important;

                &>div {
                    padding: 20px !important;
                    font-size: 12px 10% !important;
                }

                &>.img {
                    display: none !important;
                }
            }

            &>h3 {
                font-size: 17px !important;
            }

            &>p {
                font-size: 30px !important;
            }

            &>header {
                padding: 20px 0 70px !important;

                &>div {
                    font-size: 10px !important;

                    &>P {
                        font-size: 16px !important;

                        &>b {
                            &>i {
                                font-size: 10px !important;
                            }
                        }

                        &>span {
                            font-size: 30px !important;
                        }
                    }
                }
            }
        }

        .slideshow {
            .controller1 {
                left: 10px !important;
                opacity: 1 !important;
            }

            .controller2 {
                right: 10px !important;
                opacity: 1 !important;
            }

            .fiche {

                &>div {
                    font-size: 16px !important;
                    &>span{
                    font-size: 14px !important;
                }
                }
            }
        }

        .product {
            &>main {
                &>div {
                    height: 6rem !important;

                    &>div {
                        &>p {
                            font-size: 12px !important;

                        }

                        &>span {
                            font-size: 8px !important;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .home {
        .News {
            &>nav {
                padding: 15px 0 30px !important;

                &>p {
                    margin-left: 20px !important;
                    font-size: 13px !important;
                }
            }

            &>main {
                width: 75% !important;

                .controller1,
                .controller2 {
                    width: 25px !important;
                    height: 25px !important;
                    font-size: 16px !important;
                }

                // .van-swipe-item:nth-child(1) {
                //     .fiche {
                //         padding: 0 20px 0 0 !important;
                //     }
                // }

                // .van-swipe-item:nth-last-child(1) {
                //     .fiche {
                //         padding: 0 0 0 20px !important;
                //     }
                // }

                .fiche {
                    padding: 0 10px !important;

                    &>.text {
                        border-radius: 0 0 10px 10px !important;
                        padding: 20px 10px !important;

                        &>span {
                            font-size: 14px !important;
                        }

                        &>h1 {
                            font-size: 14px !important;
                            margin: 15px 0 !important;
                        }

                        &>p {
                            font-size: 10px !important;
                            margin-bottom: 15px !important;
                        }
                    }

                    &>.img {
                        height: 160px !important;
                        border-radius: 10px 10px 0 0 !important;

                        &>p {
                            filter: blur(3px) !important;
                        }
                    }
                }
            }
        }
    }
    &>.introduce {
            &>main {
                height: 200px !important;
            }
        }
}

@keyframes gradual {
    0% {
        transform: translateY(50%);
        opacity: 0;
    }

    100% {
        transform: translateY(0%);
        opacity: 1;
    }
}

@keyframes fluctuate {
    0% {
        transform: translateY(0%);
    }

    25% {
        transform: translateY(-100%);
    }

    50% {
        transform: translateY(0%);
    }

    75% {
        transform: translateY(-50%);
    }

    100% {
        transform: translateY(0%);
    }
}

.home {
    width: 100%;
    user-select: none;
    overflow: hidden;

    &>.News {
        width: 100%;
        background: #f7f7f7;
        padding: 1.5rem 0;

        &>button {
            background: #1b5fad;
            width: 1.4rem;
            height: .475rem;
            color: #fff;
            font-size: .2rem;
            border-radius: .0375rem;
            margin: .45rem auto 0;
            display: block;
        }

        &>main {
            width: 84%;
            margin: 0 auto;
            position: relative;

            .controller1,
            .controller2 {
                width: .625rem;
                height: .625rem;
                background-color: #00000070;
                border-radius: 50%;
                position: absolute;
                top: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: .275rem;
                color: #fff;
                transition: all .3s;
            }

            .controller1 {
                left: 0%;
                transform: translate(-130%, -50%);
            }

            .controller2 {
                right: 0%;
                transform: translate(130%, -50%);
            }

            // .van-swipe-item:nth-child(1) {
            //     .fiche {
            //         padding: 0 1.25rem 0 0;
            //     }
            // }

            // .van-swipe-item:nth-last-child(1) {
            //     .fiche {
            //         padding: 0 0 0 1.25rem;
            //     }
            // }

            .fiche {
                width: 100%;
                padding: 0 .625rem;
                box-sizing: border-box;

                &>.text:hover {

                    &>p,
                    &>span {
                        color: #1b5fad;
                    }

                    &>h1 {
                        color: #222;
                    }
                }

                &>.text {
                    width: 100%;
                    min-height: 3.5rem;
                    background-color: #fff;
                    border-radius: 0 0 .125rem .125rem;
                    overflow: hidden;
                    padding: .625rem .25rem;
                    box-sizing: border-box;

                    &>span {
                        color: #ccc;
                        font-size: .225rem;
                        font-weight: bold;
                        transition: all .3s;
                    }

                    &>h1 {
                        font-size: .225rem;
                        color: #353432;
                        margin: .5rem 0;
                    }

                    &>p {
                        color: #b8b8b8;
                        font-size: .175rem;
                        margin-bottom: .5rem;
                        transition: all .3s;
                        overflow: hidden;
                        word-break: break-all;
                        text-overflow: ellipsis;
                        display: -webkit-box !important;
                        -webkit-line-clamp: 6;
                        -webkit-box-orient: vertical;
                    }
                }

                &>.img {
                    width: 100%;
                    height: 3rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    border-radius: .125rem .125rem 0 0;
                    overflow: hidden;

                    &>p {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background-size: 100% 100% !important;
                        z-index: 1;
                        filter: blur(.0375rem);
                    }

                    &>img {
                        max-width: 100%;
                        max-height: 100%;
                        z-index: 2;
                    }
                }
            }
        }

        &>nav {
            width: 20rem;
            margin: 0 auto;
            padding: .25rem 0 .625rem;
            display: flex;
            justify-content: flex-end;

            &>.p {
                color: #333;

                &>i {
                    animation: fluctuate .6s linear 0s 1;
                }

                &>span {
                    width: 100%;
                }
            }

            &>p {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-left: .5rem;
                font-size: .225rem;
                color: #666;

                &>span {
                    width: 0%;
                    height: .025rem;
                    margin-top: .125rem;
                    background: #1b5fad;
                    transition: all .3s;
                }
            }
        }

        &>header {
            width: 20rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 auto .625rem;

            &>span {
                display: block;
                width: .3rem;
                min-width: .3rem;
                height: .025rem;
                background: #1b5fad;
                margin: .2rem 0 0;
            }

            &>h3 {
                font-size: .3rem;
                text-align: center;
                font-weight: 100;
            }

            &>p {
                font-size: .5625rem;
                font-weight: bold;
                text-align: center;
                color: #1b5fad;
            }
        }
    }

    &>.Cooperation {
        width: 100%;
        padding: 1.5rem 0;

        &>main {
            width: 60%;
            margin: 0 auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            &>div:hover {
                border: 1px solid #cccccc00;

                &>p:nth-child(3),
                &>p:nth-child(1) {
                    width: 100%;
                }

                &>p:nth-child(2),
                &>p:nth-child(4) {
                    height: 100%;
                }
            }

            &>div {
                width: 18%;
                position: relative;
                border: 1px solid #ccc;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: .25rem;
                padding: 1px 0;

                &>p {
                    position: absolute;
                    transition: all .3s;
                    z-index: 2;
                    background-color: #1b5fad;
                }

                &>p:nth-child(1) {
                    width: 0%;
                    height: 1px;
                    left: 50%;
                    top: -1px;
                    transform: translateX(-50%);
                }

                &>p:nth-child(2) {
                    width: 1px;
                    height: 0%;
                    position: absolute;
                    right: -1px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &>p:nth-child(3) {
                    width: 0%;
                    height: 1px;
                    position: absolute;
                    left: 50%;
                    bottom: -1px;
                    transform: translateX(-50%);
                }

                &>p:nth-child(4) {
                    width: 1px;
                    height: 0%;
                    position: absolute;
                    left: -1px;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &>img {
                    width: 80%;
                    z-index: 1;
                }
            }
        }

        &>header {
            width: 20rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 auto .625rem;

            &>span {
                display: block;
                width: .3rem;
                min-width: .3rem;
                height: .025rem;
                background: #1b5fad;
                margin: .2rem 0 0;
            }

            &>h3 {
                font-size: .3rem;
                text-align: center;
                font-weight: 100;
            }

            &>p {
                font-size: .5625rem;
                font-weight: bold;
                text-align: center;
                color: #1b5fad;
            }
        }
    }

    &>.case {
        width: 100%;
        background: #f7f7f7;
        padding: 1.5rem 0;

        &>button {
            background: #1b5fad;
            width: 1.4rem;
            height: .475rem;
            color: #fff;
            font-size: .2rem;
            border-radius: .0375rem;
            margin: .45rem auto 0;
            display: block;
        }

        &>main {
            width: 20rem;
            margin: .25rem auto 0;

            .custom-indicator {
                position: absolute;
                right: .125rem;
                top: 50%;
                transform: translateY(-50%);
                background: #00000070;
                border-radius: .1rem;
                padding: .0625rem 0;

                &>.p {
                    &>span {
                        height: .25rem;
                    }
                }

                &>p {
                    width: .2rem;
                    height: .275rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &>span {
                        width: .15rem;
                        height: .15rem;
                        background-color: #fff;
                        border-radius: .075rem;
                        transition: all .3s;
                    }
                }
            }

            .img {
                width: 100%;
                height: 100%;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;

                &>p {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    background-size: 100% 100% !important;
                    filter: blur(.0625rem);
                }

                &>img {
                    height: 100%;
                    z-index: 2;
                }

                &>.span {
                    left: 50%;
                    opacity: 1;
                }

                &>span {
                    font-size: .25rem;
                    color: #fff;
                    font-weight: bold;
                    z-index: 3;
                    position: absolute;
                    left: 0%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    transition: all 1s;
                    opacity: 0;
                }
            }
        }

        &>header {
            width: 20rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 0 auto .625rem;

            &>span {
                display: block;
                width: .3rem;
                min-width: .3rem;
                height: .025rem;
                background: #1b5fad;
                margin: .2rem 0 0;
            }

            &>h3 {
                font-size: .3rem;
                text-align: center;
                font-weight: 100;
            }

            &>p {
                font-size: .5625rem;
                font-weight: bold;
                text-align: center;
                color: #1b5fad;
            }
        }
    }

    &>.introduce {
        width: 100%;
        margin: .875rem 0 1.5rem;
        opacity: 0;

        &>main {
            width: 85%;
            height: 5rem;
            margin: 0 auto;
            border-radius: .125rem;
            overflow: hidden;
            box-shadow: 0 .025rem .375rem rgba(0, 0, 0, 0.1);
            display: flex;

            &>div {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: .25rem 10%;
                box-sizing: border-box;
                font-size: .2rem;

                &>span {
                    min-width: 250px;
                    overflow: hidden;
                    word-break: break-all;
                    text-overflow: ellipsis;
                    display: -webkit-box !important;
                    -webkit-line-clamp: 6;
                    -webkit-box-orient: vertical;
                }
            }

            &>.img {
                width: auto;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                &>img{
                    height: 100%;
                }
            }
        }

        &>span {
            display: block;
            margin: 0 auto;
            width: .3rem;
            height: .025rem;
            background: #1b5fad;
            margin: .2rem auto 0;
        }

        &>h3 {
            font-size: .3rem;
            text-align: center;
            font-weight: 100;
        }

        &>p {
            font-size: .5625rem;
            font-weight: bold;
            text-align: center;
            color: #1b5fad;
        }

        &>header {
            width: 100%;
            padding: .625rem 0 1.5rem;
            display: flex;

            &>div {
                width: 25%;
                display: flex;
                flex-direction: column;
                align-items: center;
                color: #999;
                font-size: .2rem;

                &>P {
                    font-size: .375rem;
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    color: #333;

                    &>b {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                        margin-left: .125rem;
                        color: #1b5fad;

                        &>i {
                            font-size: .2rem;
                            color: #999;
                        }
                    }

                    &>span {
                        font-size: .75rem;
                    }
                }
            }
        }
    }

    &>.product {
        width: 100%;
        padding: 120px 0;
        box-sizing: border-box;
        background: #f7f7f7;
        opacity: 0;

        &>button {
            background: #1b5fad;
            width: 1.4rem;
            height: .475rem;
            color: #fff;
            font-size: .2rem;
            border-radius: .0375rem;
            margin: .45rem auto 0;
            display: block;
        }

        &>main {
            width: 20rem;
            display: flex;
            justify-content: space-around;
            margin: 20px auto;

            &>div:hover {
                &>div {
                    opacity: 1;

                    &>* {
                        transform: translateY(0px);
                    }
                }

                &>img {
                    min-width: 110%;
                    min-height: 110%;
                }
            }

            &>div {
                width: 4.5rem;
                height: 2.8125rem;
                background-color: #fff;
                border-radius: 10px;
                overflow: hidden;
                margin-top: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                &>div {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background: #00000070;
                    color: #fff;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    opacity: 0;

                    &>* {
                        text-align: center;
                        transform: translateY(80px);
                        transition: all .3s;
                    }

                    &>p {
                        font-size: .2rem;

                    }

                    &>span {
                        font-size: .175rem;
                        margin-top: .375rem;
                    }
                }

                &>img {
                    min-width: 100%;
                    min-height: 100%;
                    transition: all .3s;
                }
            }
        }

        &>header {
            max-width: 20rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            margin: 0 auto;

            &>div {
                width: 16rem;
                display: flex;
                justify-content: space-around;
                margin-top: 50px;

                &>div {
                    width: 1.625rem;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    box-sizing: border-box;
                    padding: .25rem 0;
                    text-align: center;

                    &>span {
                        font-size: .2rem;
                        margin-top: 20px;
                    }

                    &>i {
                        font-size: .15rem;
                        margin-top: 20px;
                    }

                    &>p {
                        width: .85rem;
                        height: .85rem;
                        border: 1px solid #e5e5e5;
                        border-radius: 50%;
                        position: relative;
                        transition: all .3s;

                        &>img {
                            transition: all .4s;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                        }

                        &>img:nth-child(1) {
                            width: 60%;
                            height: 60%;
                            z-index: 1;
                            transition: all .5s;
                        }

                        &>img:nth-child(2) {
                            width: 0%;
                            height: 0%;
                            z-index: 2;
                        }
                    }
                }

                &>.div {
                    color: #1b5fad;

                    &>p {
                        background: #1b5fad;
                        border: 1px solid #1b5fad;

                        &>img:nth-child(2) {
                            width: 60%;
                            height: 60%;
                        }

                        &>img:nth-child(1) {
                            opacity: 0;
                        }
                    }
                }
            }

            &>nav {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;

                &>span {
                    display: block;
                    width: .3rem;
                    min-width: 24px;
                    height: .025rem;
                    background: #1b5fad;
                    margin: 16px 0 0;
                }

                &>h3 {
                    font-size: .3rem;
                    text-align: center;
                    font-weight: 100;
                }

                &>p {
                    font-size: .5625rem;
                    font-weight: bold;
                    text-align: center;
                    color: #1b5fad;
                }
            }
        }
    }

    &>.Cartoon {
        animation: gradual .8s linear 0s 1;
        animation-fill-mode: forwards;
    }

    &>.ServiceItem {
        width: 100%;
        padding: 1.5rem 0 .5rem;
        opacity: 0;

        &>div {
            width: 100%;
            display: flex;
            justify-content: space-around;

            &>article:hover {
                transform: scale(1.1);
                box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);
                border: 0;

                &>p {
                    color: #1b5fad;
                }

                &>h3 {
                    color: #1b5fad;
                }
            }

            &>article {
                width: 20%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 30px 20px 1.5rem;
                box-sizing: border-box;
                position: relative;
                overflow: hidden;
                transition: all .3s;
                background: #fff;

                &>p {
                    font-size: .1875rem;
                    color: #888;
                    text-align: center;
                    transition: all .3s;
                }

                &>h3 {
                    font-size: .225rem;
                    margin: .125rem auto;
                    text-align: center;
                    color: #5185c0;
                }

                &>img {
                    width: 1.5rem;
                }
            }
        }

        &>span {
            display: block;
            margin: 0 auto;
            width: .3rem;
            height: .025rem;
            background: #1b5fad;
            margin: .2rem auto 0;
        }

        &>h3 {
            font-size: .3rem;
            text-align: center;
            font-weight: 100;
        }

        &>p {
            font-size: .5625rem;
            font-weight: bold;
            text-align: center;
            color: #1b5fad;
        }
    }

    &>.slideshow:hover {
        .controller1 {
            left: .125rem;
            opacity: 1;
        }

        .controller2 {
            right: .125rem;
            opacity: 1;
        }
    }

    &>.slideshow {
        width: 100%;
        height: 11.5rem;
        position: relative;

        .controller1,
        .controller2 {
            width: .625rem;
            height: .625rem;
            background-color: #00000070;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: .275rem;
            color: #fff;
            transition: all .3s;
            opacity: 0;
        }

        .controller1 {
            left: -100%;
            transform: translateY(-50%);
        }

        .controller2 {
            right: -100%;
            transform: translateY(-50%);
        }

        .custom-indicator {
            display: flex;
            position: absolute;
            bottom: 10px;
            left: 50%;
            transform: translateX(-50%);

            &>.p {
                &>span {
                    left: 0;
                    transition: all 3s linear;
                }
            }

            &>p {
                min-width: 20px;
                width: .25rem;
                min-height: 3px;
                height: .0375rem;
                background-color: #00000070;
                margin: 0 5px;
                position: relative;
                overflow: hidden;

                &>span {
                    width: 100%;
                    height: 100%;
                    background-color: #ccc;
                    position: absolute;
                    left: -100%;
                    top: 0;
                }
            }
        }

        .fiche {
            width: 100%;
            display: flex;
            overflow: hidden;
            align-items: center;
            justify-content: center;
            position: relative;

            &>.div {
                top: 50%;
                color: #ffffff;
            }

            &>div {
                position: absolute;
                left: 50%;
                top: 90%;
                transition: all .8s;
                z-index: 10;
                transform: translate(-50%, -50%);
                color: #ffffff00;
                font-size: .4rem;
                &>p{
                    margin-top: 10px;
                }
                &>span{
                    display: block;
                    font-size: .3rem;
                    margin-top: 20px;
                }
            }

            &>img {
                width: 100%;
                min-height: 100%;
                display: block;
            }
        }
    }
}
</style>