import Router from "vue-router";

import home from "@/view/home.vue";

const routerPush = Router.prototype.push;
Router.prototype.push = function(location) {
    return routerPush.call(this, location).catch(err => {})
};

let routes = [{
    path:'/',
    redirect:'/home'
},
{
    path:'/home',
    name: '/home',
    component: home,
    children:[]
},{
    path:'/contact',
    name:'/contact',
    component:()=> import('@/view/contact.vue')
},{
    path:'/product',
    name:'/product',
    component:()=> import('@/view/product.vue')
},{
    path:'/journalism',
    name:'/journalism',
    component:()=> import('@/view/journalism.vue')
},{
    path:'/engineering',
    name:'/engineering',
    component:()=> import('@/view/engineering.vue')
},{
    path:'/details',
    name:'/details',
    component:()=> import('@/view/details.vue')
},{
    path:'/case',
    name:'/case',
    component:()=> import('@/view/case.vue')
}
]

let router = new Router({
    routes,
})

export default router