<!-- 回到顶部 -->
<template>
    <div :class="['top', { 'concealtop': !fage }]" @click="$customApi.scrollToTop(0)" v-if="show">
        <i class="el-icon-upload2"></i>
    </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
    name: "top",
    //import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        //这里存放数据
        return {
            show: false,
        };
    },
    props: ['fage'],
    //计算属性 类似于data概念
    computed: {},
    //监控data中的数据变化
    watch: {
        fage(val, old) {
            if (val) {
                this.show = val
                return
            }
            setTimeout(() => {
                this.show = val
            }, 300)
        }
    },
    //方法集合
    methods: {

    },
    beforeCreate() { }, //生命周期 - 创建之前
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    beforeMount() { }, //生命周期 - 挂载之前
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，激活
    deactivated() { }, //如果页面有keep-alive缓存功能，非激活
}
</script>
<style lang="less">

@media screen and (max-width: 1000px) {
    .top {
        display: none !important;
    }
}

.concealtop {
    opacity: 0 !important;
}

.top {
    width: .75rem;
    height: .75rem;
    border-radius: 50%;
    background-color: rgba(27, 95, 173, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 1.25rem;
    right: .5rem;
    transition: all 0.3s;
    opacity: 1;
    cursor: pointer;

    &>i {
        font-size: .45rem;
        color: #fff;
    }
}
</style>