import axios from "axios";
// import router from '@/router/index';

// let baseURL = 'https://hangtu.hn8090kj.com'
let baseURL = ''

let http = axios.create({
    baseURL: baseURL+'/api',
    timeout: 10000,
});

// 请求拦截器
http.interceptors.request.use(config => {
    // 根据请求方法和参数设置 Content-Type
    if (config.url === '/image/upload') {
        config.headers['Content-Type'] = 'multipart/form-data'
    } else {
        config.headers['Content-Type'] = 'application/json'
    }
    config.headers.token = window.localStorage.getItem("__token__")
    return config;
},err=>{
    this.$message({
        message: '网络错误',
        type: 'warning'
    });
    console.log(err)
});

// 响应拦截器
http.interceptors.response.use(
    Response => {
        if (Response.data.code === -1) {
            // localStorage.removeItem("__token__");
            // router.push({ path: '/login' })
            this.$message({
                message: Response.data.msg,
                type: 'warning'
            });
        } else if (Response.data.code === 0) {
            this.$message({
                message: Response.data.msg,
                type: 'warning'
            });
        } else if(Response.data.code){
            return Response.data;
        }
    },
    error => {
        console.error(error, "error");
    }
);

// post请求
const post = (url,data)=>http.post(url,data)

export default{ post,baseURL,}